import React, { useState } from "react";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    emailAddress: "", // Updated to emailAddress
    message: "",
  });
  const [emailStatus, setEmailStatus] = useState("");
  const [loading, setLoading] = useState(false);
  // Handle the value changes
  const handleChange = (input) => (e) => {
    setFormData({
      ...formData,
      [input]: e.target.value,
    });
  };

  // When the submit button is clicked
  const submitForm = (e) => {
    e.preventDefault();
    setLoading(true);

    const { name, phone, emailAddress, message } = formData;
    console.log(name, phone, emailAddress, message);
    // Create a new XMLHttpRequest
    var xhr = new XMLHttpRequest();

    // Get a callback when the server responds
    xhr.addEventListener("load", () => {
      // Update the response state
      setLoading(false);
      setEmailStatus(xhr.responseText);
    });

    // Open the request with the verb and the URL
    xhr.open(
      "GET",
      "https://quadbrains.com/sendemail/index.php?sendto=" +
        encodeURIComponent(emailAddress) +
        "&name=" +
        encodeURIComponent(name) +
        "&phone=" +
        encodeURIComponent(phone) +
        "&message=" +
        encodeURIComponent(message)
    );

    // Send the request
    xhr.send();

    // Reset the fields
    setFormData({
      name: "",
      phone: "",
      emailAddress: "",
      message: "",
    });
  };

  const { name, phone, emailAddress, message } = formData;

  return (
    <div>
      {/* page-title */}
      <div className="prt-page-title-row">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box text-center">
                <div className="page-title-heading">
                  <h1 className="title">Contact Us</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a title="Homepage" href="/">
                      <i className="ti ti-home" />
                      Home
                    </a>
                  </span>
                  <span className="prt-bread-sep">: :</span>
                  <span>Contact Us</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>
      {/* page-title end*/}
      {/*site-main start*/}
      <div className="site-main">
        {/* services-slide-section */}
        <section className="prt-row zero-padding2-section clearfix">
          <div className="container">
            <div className="row no-gutters">
              {/* row */}
              <div className="col-lg-5">
                <div className="spacing-9">
                  {/* section title */}
                  <div className="section-title with-desc clearfix">
                    <div className="title-heading">
                      <div className="sub-title">
                        <span>Come Visit Us At</span>
                      </div>
                      <h2 className="title">Our Address</h2>
                    </div>
                  </div>
                  {/* section title end */}
                  <div className="row">
                    <div className="col-12">
                      {/* featured-icon-box */}
                      <div className="featured-icon-box style4 left-icon icon-align-top">
                        <div className="featured-icon">
                          {/* featured-icon */}
                          <div className="prt-icon prt-icon_element-bgcolor-skincolor prt-icon_element-size-md prt-icon_element-style-round">
                            <i className="fa fa-map-marker" />
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            {/* featured title */}
                            <h5>Office Address</h5>
                          </div>
                          <div className="featured-desc">
                            {/* featured desc */}
                            <p>Mumbai, Maharashtra</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* separator */}
                  <div className="separator mb-5 res-991-mt-15 res-991-mb-15">
                    <div className="sep-line" />
                  </div>
                  {/* separator */}
                  <div className="row">
                    <div className="col-12">
                      {/* featured-icon-box */}
                      <div className="featured-icon-box style4 left-icon icon-align-top pb-12">
                        <div className="featured-icon">
                          {/* featured-icon */}
                          <div className="prt-icon prt-icon_element-bgcolor-skincolor prt-icon_element-size-md prt-icon_element-style-round">
                            <i className="fa fa-phone" />
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            {/* featured title */}
                            <h5>Our Phone Number</h5>
                          </div>
                          <div className="featured-desc">
                            {/* featured desc */}
                            <p>
                              <a href="tel:+91-9326-866-949">
                                +91-9326-866-949
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* separator */}
                  <div className="separator mb-5 res-991-mb-15">
                    <div className="sep-line" />
                  </div>
                  {/* separator */}
                  <div className="row">
                    <div className="col-12">
                      {/* featured-icon-box */}
                      <div className="featured-icon-box style4 left-icon icon-align-top">
                        <div className="featured-icon">
                          {/* featured-icon */}
                          <div className="prt-icon prt-icon_element-bgcolor-skincolor prt-icon_element-size-md prt-icon_element-style-round">
                            <i className="ti ti-email" />
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            {/* featured title */}
                            <h5>Our Email</h5>
                          </div>
                          <div className="featured-desc">
                            {/* featured desc */}
                            <p>
                              <a href="mailto:info@quadbrains.com">
                                info@quadbrains.com
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="prt-bg prt-col-bgcolor-yes prt-right-span">
                  <div className="prt-col-wrapper-bg-layer prt-bg-layer">
                    <div className="prt-bg-layer-inner" />
                  </div>
                  {/* section title */}
                  <div className="section-title with-desc clearfix res-991-mt-50">
                    <div className="title-heading">
                      <div className="sub-title">
                        <span>Send Message</span>
                      </div>
                      <h2 className="title">
                        Let's work together towards growth
                      </h2>
                    </div>
                  </div>
                  {/* section title end */}
                  <div className="formBlock">
                    {emailStatus && (
                      <div className="response prt-btn prt-btn-size-sm prt-btn-shape-rounded prt-btn-style-border w-100" style={{marginBottom: "10px;"}}>
                        {emailStatus === "Message has been sent!"? "Thank you for contacting us. We will get back to you soon.": emailStatus}
                      </div> 
                    )}
                    <div>
                      <br></br>
                    </div>
                    <form
                      id="contactform"
                      className="row prt-quote-form clearfix"
                      onSubmit={submitForm}
                    >
                      <div className="comment-respond row">
                        <div className="col-sm-6 col-md-12 col-lg-6">
                          <div className="form-group">
                            <input
                              id="name"
                              name="name"
                              type="text"
                              className="username form-control bg-white border"
                              placeholder="Full Name*"
                              required
                              value={name}
                              onChange={handleChange("name")}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-12 col-lg-6">
                          <div className="form-group">
                            <input
                              id="phone"
                              name="phone"
                              type="text"
                              placeholder="Phone Number*"
                              required
                              className="phone form-control bg-white border"
                              value={phone}
                              onChange={handleChange("phone")}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-12">
                          <div className="form-group">
                            <input
                              id="emailAddress"
                              name="emailAddress"
                              type="email" // changed to 'email'
                              placeholder="Email Address*"
                              required
                              className="email form-control bg-white border"
                              value={emailAddress}
                              onChange={handleChange("emailAddress")}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-12">
                          <div className="form-group">
                            <textarea
                              id="message"
                              name="message"
                              rows={5}
                              placeholder="Write A Message..."
                              required
                              className="message form-control bg-white border"
                              value={message}
                              onChange={handleChange("message")}
                            />
                          </div>
                        </div>
                        <div className="text-left">
                          <button
                            className="prt-btn prt-btn-size-sm prt-btn-shape-rounded prt-btn-style-border w-100"
                            type="submit"
                            name="submit-form"
                            disabled={loading} // Disable button while loading
                          >
                            {loading ? "Sending Email..." : "Submit"}{" "}
                            {/* Change button label */}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* row end */}
            {/* row */}
            <div className="row"></div>
            {/* row end*/}
          </div>
        </section>
        {/* services-slide-section end */}
        {/* map-section */}
        <div className="prt-row map-section clearfix">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                {/*map-start*/}
                <div className="map-wrapper">
                  <div className="map_container">
                    <div id="map">
                      {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.5095435809484!2d72.96957031420342!3d19.21833098700959!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b91d3d1dbe1d%3A0x50358b9d2c0aa80!2sThane%2C%20Maharashtra%2C%20India!5e0!3m2!1sen!2sin!4v1693627008459!5m2!1sen!2sin"  height={590} /> */}
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3780.195964024051!2d72.85616431498195!3d19.076090287017885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c8fe6c8dfb9f%3A0x8e7f16a9065c3ef!2sMumbai%2C%20Maharashtra%2C%20India!5e0!3m2!1sen!2sin!4v1693627990653!5m2!1sen!2sin"
                        width="600"
                        height="450"
                        style={{ border: "0" }}
                        allowfullscreen=""
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
                {/*map-end*/}
              </div>
            </div>
          </div>
        </div>
        {/* map-section end */}
      </div>
      {/*site-main end*/}
    </div>
  );
};

export default ContactUs;
